import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of } from 'rxjs';

import {
    PermissionAction,
    PermissionCategory,
    Permissionss,
    permission,
} from '../../core/authentication/authentication.service';
@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    public permissionSubject = new BehaviorSubject<permission[]>([]);
    permissionSubject$ = this.permissionSubject.asObservable();
    PermissionAction = PermissionAction;
    PermissionCategory = PermissionCategory;
    permissions: Permissionss;

    getPermissions(): Observable<Permissionss> {
        return this.permissionSubject$.pipe(
            map((permission) => {
                return permission[0].permissions;
            })
        );
    }
    PermissionAccess(
        PermissionCategory: PermissionCategory,
        PermissionAction: PermissionAction
    ): Observable<boolean> {
        return this.permissionSubject$.pipe(
            map((permissions) => {
                const permissionsObj = permissions[0]?.permissions;
                if (!permissionsObj) {
                    return false;
                }
                const grantPermission =
                    permissionsObj[PermissionCategory]?.[PermissionAction];
                return grantPermission === true;
            })
        );
    }
}
